import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Say `}<a parentName="p" {...{
        "href": "advenoh@gmail.com"
      }}>{`Hi`}</a>{` or find me on other platforms: `}<a parentName="p" {...{
        "href": "https://https://github.com/kenshin579"
      }}>{`Github`}</a>{` & `}<a parentName="p" {...{
        "href": "https://www.instagram.com/kenshin579/"
      }}>{`Instagram`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      